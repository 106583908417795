import * as React from "react"
import { Carousel, Modal } from "react-bootstrap"
import Masonry from "react-masonry-component"

const CarouselWidget = ({ title, images, selectedIndex }) => {
  const [activeIndex, setActiveIndex] = React.useState(selectedIndex)

  return (
    <Carousel
      interval={null}
      activeIndex={activeIndex}
      onSelect={setActiveIndex}
    >
      {images.map(({ image }, i) => (
        <Carousel.Item key={i}>
          <img className="d-block" src={image} alt={title} />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

const ModalWidget = ({ images, show, onHide, selectedIndex }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      dialogClassName="gallery"
    >
      <div className="position-relative">
        <p
          className="position-absolute top-0 end-0"
          role="button"
          onClick={onHide}
          style={{ zIndex: 3 }}
        >
          X
        </p>
      </div>
      <CarouselWidget images={images} selectedIndex={selectedIndex} />
    </Modal>
  )
}

const GalleryLightbox = ({ carousel }) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  return (
    <>
      <ModalWidget
        show={modalShow}
        onHide={() => setModalShow(false)}
        images={carousel.images}
        selectedIndex={selectedIndex}
      />
      <Masonry className="row row-cols-1 row-cols-md-3">
        {carousel.images.map(({ image }, i) => (
          <div key={i} className="col mb-3">
            <img
              className="d-block w-100"
              role="button"
              src={image}
              alt={carousel.title}
              onClick={() => {
                setModalShow(true)
                setSelectedIndex(i)
              }}
            />
          </div>
        ))}
      </Masonry>
    </>
  )
}

const GalleryCollection = ({ frontmatter }) => {
  const carousels = frontmatter.carousels

  return carousels.map((carousel, i) => (
    <div key={i} className="mb-3">
      <h4>{carousel.title}</h4>
      <p>{carousel.description}</p>
      <GalleryLightbox carousel={carousel} />
    </div>
  ))
}

export default GalleryCollection
