import * as React from "react"
import { graphql } from "gatsby"

import GalleryLightbox from "../components/gallery-lightbox"
import Layout from "../components/layout"
import Seo from "../components/seo"

const GalleryIndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout title={"Gallery"}>
      <Seo title={"Gallery"} />
      {posts.map(post => (
        <div className="mb-3" key={post.id}>
          <h2>{post.frontmatter.title}</h2>
          {/* <p className="text-muted">{post.frontmatter.date}</p> */}
          <GalleryLightbox {...post} />
        </div>
      ))}
    </Layout>
  )
}

export default GalleryIndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(gallery)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        html
        frontmatter {
          title
          date
          carousels {
            title
            description
            images {
              image
            }
          }
        }
      }
    }
  }
`
